import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridVenteToolbar from "components/DataGridVenteToolbar";
import { server } from "server";
import axios from "axios";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { CancelPresentationSharp, CheckBox, DeleteOutline } from "@mui/icons-material";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import useScanDetection from "use-scan-detection";
import CartItem from "./CartItem";

const Sells = () => {
  const theme = useTheme();

  // State management
  const [openDelete, setOpenDelete] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [Id, setId] = useState(false);
  const [sales, setSales] = useState([]);
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  const [clientName, setClientName] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('CASH');
  
  const [name, setName] = useState("");
  const [ClientId, setClientId] = useState(null);
  const [clientType, setClientType] = useState("")
  const [clientList, setClientList] = useState([]);
  const [selectedTab, setSelectedTab] = React.useState(0);

  //CLIENT TYPE FUNCTIONS

  // Fetch sales and client data
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${server}/client/get-all-clients`);
        setClientList(response.data.client);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    fetchClients();
  }, []);

  // Function to filter clients by type
  const handleClientListFilter = (clientType) => {
    if (!clientList || clientList.length === 0) {
      return [];
    }
    return clientList.filter((client) => client.clientType === clientType);
  };
  // Function to handle payment method change
  const handlePaymentMethodChange = (newMethod) => {
    setPaymentMethod(newMethod);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    // Only reset customerName if switching from Tab 0 to Tab 1
    setName("");  // Reset if switching to the second tab without a clientType selected
    setClientType("");  // Reset client type when switching tabs
  };

  // Fetch sales data
  useEffect(() => {
    axios.get(`${server}/sells/get-all-sales`).then((res) => {
      setSales(res.data.allSales);
    });
  }, []);

  // Handle quantity change
  const handleQuantityChange = (productId, newQuantity) => {
    setCart((prevCart) =>
      prevCart.map((item) =>
        item._id === productId ? { ...item, quantity: Number(newQuantity) } : item
      )
    );
  };

  // Fetch sale data and generate PDF
  const generatePDF = async (id) => {
    try {
      // Fetch sale data from server
      const response = await axios.get(`${server}/sells/get-sale/${id}`);
      const sale = response.data.Sale;
  
      // Dynamically calculate document height based on the number of products
      const productCount = sale.products.length;
      const baseHeight = 200; // Base height for standard receipt
      const additionalHeight = productCount * 15; // Extra height for each product
      const docHeight = baseHeight + additionalHeight;
  
      // Initialize jsPDF document with 80mm width and dynamic height
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [80, docHeight], // Width: 80mm, dynamic height
      });
  
      // Extract necessary data from sale object
      const {
        nomClient,
        products,
        totalPrice,
        paymentMethod,
        createdAt,
        invoiceNumber,
      } = sale;
  
      // Add logo if available
      const imgData = Logo; // Replace with your base64 encoded logo image
      if (imgData) {
        doc.addImage(imgData, "PNG", 25, 5, 30, 30); // Centered at 25mm
      }
  
      // Add line separator function
      const drawLine = (doc, yPosition) => {
        doc.line(5, yPosition, 75, yPosition); // Draw a line between x=5 and x=75 at yPosition
      };
  
      // Title and Company Info
      let yPosition = 40; // Start a bit lower if a logo is present
      doc.setFontSize(16);
      doc.text("Facture", 40, yPosition, null, null, "center");
  
      // Company information
      doc.setFontSize(10);
      yPosition += 10;
      doc.text(`Nom de l'entreprise: Bismos High Tech`, 5, yPosition);
      doc.text("NIF: 400045190 / RC: 03436", 5, (yPosition += 5));
      doc.text("Avenue: KIGARAMA, Ngozi N0: 21", 5, (yPosition += 5));
      doc.text("Centre Fiscal: Ressort Nord", 5, (yPosition += 5));
      doc.text("Secteur d'activité: COM. General", 5, (yPosition += 5));
      doc.text("Forme Juridique: Personne Morale", 5, (yPosition += 5));
  
      // Invoice details
      yPosition += 10;
      doc.text(`N0 du facture: ${invoiceNumber}`, 5, yPosition);
      doc.text(`Fait le: ${createdAt?.slice(0, 10)}`, 5, (yPosition += 5));
  
      // Payment method and customer name
      doc.text(`Méthode de paiement: ${paymentMethod}`, 5, (yPosition += 10));
      doc.text(`Nom du client: ${nomClient}`, 5, (yPosition += 5));
  
      // Add checkboxes below Numero de contact
      doc.setFontSize(10);
      doc.text("Assujetti à la TVA :", 5, yPosition += 8);
      doc.rect(38, yPosition - 4, 4, 4); // Checkbox 1
      doc.text("Oui", 43, yPosition);
      doc.rect(51, yPosition - 4, 4, 4); // Checkbox 2
      doc.text("Non", 56, yPosition);
  
      drawLine(doc, yPosition += 2);
  
      // Product listing
      yPosition += 10;
      doc.setFontSize(11);
      doc.text("Détails des produits:", 5, yPosition);
  
      products.forEach((product, index) => {
        yPosition += 8;
  
        // Format product details
        const productText = `${index + 1}. ${product.productName}`;
        const quantityText = `Qte: ${product.quantity}`;
        const unitPriceText = `PU: ${product.price} Fbu`;
        const totalPriceText = `Total: ${product.ProductTotal} Fbu`;
  
        // Add product name
        doc.text(productText, 5, yPosition);
  
        // Add quantity, unit price, and total price
        yPosition += 5;
        doc.text(`${quantityText} | ${unitPriceText}`, 5, yPosition);
        doc.text(totalPriceText,5, yPosition + 5); // Align total price to the right
  
        // Draw line after each product
        drawLine(doc, yPosition += 10);
      });
  
      // Total Price
      yPosition += 10;
      doc.setFontSize(11);
      doc.text(`Prix Total: ${totalPrice} Fbu`, 35, yPosition);
  
      // Footer Information
      yPosition += 15;
      doc.setFontSize(8);
      doc.text("Tel: 79 967 624 / 22303165", 40, yPosition, null, null, "center");
      doc.text("KCB:06691079043 | Bancobu:20002-11109-06571220101-09", 40, (yPosition += 5), null, null, "center");
      doc.text("www.bismos.com | E-mail: info@bismos.com", 40, (yPosition += 5), null, null, "center");
  
      // Save the PDF
      const filename = `facture_${invoiceNumber}.pdf`;
      doc.save(filename);
  
      // Show success toast
      toast.success("Le PDF a été créé avec succès");
  
    } catch (error) {
      console.error("Error generating PDF:", error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };
  

  // Delete sale
  const handleDelete = (id) => {
    axios
      .delete(`${server}/sells/delete-sale/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  // Add product to cart
  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item._id === product._id);
      if (existingProduct) {
        // Update quantity if product already exists in cart
        return prevCart.map((item) =>
          item._id === product._id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }
      // Add new product to cart
      return [...prevCart, { ...product, quantity: 1 }];
    });
  };

  const handleCheckout = async () => {
    if (cart.length === 0) {
      return alert("Cart is empty");
    }

    const products = cart.map((item) => ({
      productId: item._id,
      quantity: item.quantity,
      price_vente: item.price_vente,
    }));

    const saleData = {
      products,
      userId, // Make sure userId is defined in your component
      customerName: clientName, // You might want to change this
      paymentMethod: paymentMethod, // Replace with the actual payment method
    };
    const CreditData = {
      userId,
      products,
      clientType,
      clientId:ClientId
    };

    try {
      const endpoint = selectedTab === 0 ? `${server}/sells/api/ventes` : `${server}/credit/api/purchase-credit`
      const data = selectedTab === 0 ? saleData : CreditData;
      const response = await axios.post(endpoint, data );
      if (response.data.success) {
        toast.success("Checkout successful!");
        setCart([]); // Clear the cart after successful checkout
        setTimeout(() => {
          window.location.replace("/ventes");
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Une erreur est survenue");
    }
  };


  const onRemove = (productId) => {
    // Logic to remove the product from the cart
    setCart((prevCart) => prevCart.filter((item) => item._id !== productId));
  };

  // Barcode scanning logic
  useScanDetection({
    onComplete: async (barcode) => {
      setLoading(true);
      setError("");
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        const foundProduct = response.data.allproducts.find(
          (p) => p.barcode === barcode
        );
        if (foundProduct) {
          addToCart(foundProduct);
        } else {
          toast.error("Produit non trouvé");
        }
      } catch (error) {
        alert("Error fetching the product");
        console.error("Error fetching the product:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  // Data grid columns
  const columns = [
    {
      field: "createdAt",
      headerName: "créer du:",
      flex: 1,
    },
    {
      field: "nomClient",
      headerName: "Nom du client",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Effectué par",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "action",
      headerName: "Facturation",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => generatePDF(params.row.id)}
            >
              <img
                src={pdfPicture}
                alt=""
                style={{
                  width: "28px",
                  height: " 28px",
                  marginRight: " 10px",
                  transition: "transform 0.3s",
                }}
              />
            </div>
            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() =>
                  setId(params.row.id) || setOpenDelete(!openDelete)
                }
              />
            )}
          </>
        );
      },
    },
  ];

  const row = [];
  sales &&
    sales.forEach((item) => {
      row.push({
        id: item._id,
        url: item.url,
        name: item.username,
        nomClient: item.nomClient,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Ventes" subtitle="Liste des Produits vendus" />
      <br />
      {/* Cart Summary or Cart Items */}
      <CartItem
        cart={cart}
        onCheckout={handleCheckout}
        onRemove={onRemove}
        onQuantityChange={handleQuantityChange} // Pass the handler
        clientName={clientName}  // Pass client name
        setClientName={setClientName}
        paymentMethod={paymentMethod}  // Pass payment method
        onPaymentMethodChange={handlePaymentMethodChange} // Pass payment method change function
        handleClientListFilter={handleClientListFilter}
        name ={name}
        setName = {setName}
        clientType = {clientType}
        setClientType ={setClientType}
        selectedTab ={selectedTab}
        setClientId ={setClientId}
        clientList = {clientList}
        handleTabChange ={handleTabChange}
      />
      <br />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridVenteToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <PopupConfirm
        title="Voulez-vous supprimer le produit "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(Id)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <CancelPresentationSharp />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>

    </Box>
  );
};

export default Sells;
