import { Box, Button, TextField, IconButton, Select, MenuItem } from "@mui/material";
import { useState } from "react";
import axios from "axios";
import { server } from "../../../src/server";
import Header from "components/Header";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const FormClient = ({ setOpenPopup, editClient, onSuccess }) => {
    const [formData, setFormData] = useState({
        name: editClient?.name || '',
        cni: editClient?.cni || '',
        phoneNumber: editClient?.phoneNumber || '',
        address: editClient?.address || '',
        clientType: editClient?.clientType || 'personnel'
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (editClient) {
                // Update existing client
                await axios.put(`${server}/client/clientTypes/${editClient.id}`, formData);
                toast.success('Client modifié avec succès');
            } else {
                // Create new client
                await axios.post(`${server}/client/clientTypes`, formData);
                toast.success('Client créé avec succès');
            }
            onSuccess(); // Refresh the client list
            setOpenPopup(false);
        } catch (error) {
            console.error(error);
            toast.error('Erreur lors de l\'enregistrement du client');
        }
    };

    return (
        <Box m="20px">
            {/* Header */}
            <Box display="flex" justifyContent="space-between">
                <Header title="Créer un produit" />
                <IconButton>
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => setOpenPopup(false)}
                    >
                        X
                    </Button>
                </IconButton>
            </Box>
            {/* Formik form */}
            <form onSubmit={handleSubmit}>
                <Box
                    display="grid"
                    gap="20px"
                    gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
                >
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Nom du client/Organiation"
                        value={formData.name}
                        onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="CNI / NIF"
                        value={formData.cni}
                        onChange={(e) => setFormData({ ...formData, cni: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Numero de Telephone"
                        value={formData.phoneNumber}
                        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                    />
                    <TextField
                        fullWidth
                        variant="filled"
                        type="text"
                        label="Adresse"
                        value={formData.address}
                        onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    />
                    <Select
                        label="Type de Client"
                        value={formData.clientType}
                        onChange={(e) => setFormData({ ...formData, clientType: e.target.value })}
                        variant="outlined"
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value="">--Sélectionner le type de client--</MenuItem>
                        <MenuItem value="personnel">personnel</MenuItem>
                        <MenuItem value="contractuel">contractuel</MenuItem>
                    </Select>
                </Box>
                <Box mt="20px">
                    <Button type="submit" variant="contained" color="primary">
                        Ajouter le client
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default FormClient;
