import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { 
    Box, 
    Container, 
    Typography, 
    Tabs, 
    Tab, 
    useTheme,
    IconButton,
    Button
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { server } from 'server';
import DataGridCustomToolbar from 'components/DataGridCustomToolbar';
import FormClient from 'scenes/formClient';
import Popup from 'components/Popup';
import { toast } from 'react-toastify';

const ClientTab = () => {
    const theme = useTheme();
    const [clients, setClients] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const [openPopup, setOpenPopup] = useState(false);
    const [editClient, setEditClient] = useState(null);

    // Fetch clients data
    const fetchClients = () => {
        axios.get(`${server}/client/get-all-clients`)
            .then(response => setClients(response.data.client))
            .catch(error => {
                console.log(error);
                toast.error("Erreur lors du chargement des clients");
            });
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    // Handle delete client
    const handleDelete = async (id) => {
        if (window.confirm('Êtes-vous sûr de vouloir supprimer ce client ?')) {
            try {
                await axios.delete(`${server}/client/clientTypes/${id}`);
                toast.success('Client supprimé avec succès');
                fetchClients();
            } catch (error) {
                console.error(error);
                toast.error('Erreur lors de la suppression du client');
            }
        }
    };

    // Handle edit client
    const handleEdit = (client) => {
        setEditClient(client);
        setOpenPopup(true);
    };

    // Handle popup close
    const handleClosePopup = () => {
        setOpenPopup(false);
        setEditClient(null);
    };

    // Define columns for DataGrid
    const columns = [
        { field: 'name', headerName: 'Nom', flex: 1 },
        { field: 'cni', headerName: 'CNI/NIF', flex: 1 },
        { field: 'phoneNumber', headerName: 'Numéro de téléphone', flex: 1 },
        { field: 'address', headerName: 'Adresse', flex: 1 },
        { field: 'clientType', headerName: 'Type de client', flex: 1 },
        { field: 'createdAt', headerName: 'Created At', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <IconButton 
                        onClick={() => handleEdit(params.row)}
                    >
                        <EditIcon />
                    </IconButton>
                    <IconButton 
                        onClick={() => handleDelete(params.row.id)}
                        color="error"
                    >
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ),
        },
    ];

    const filteredClients = clients.filter(client =>
        tabValue === 0 ? client.clientType === 'personnel' : client.clientType === 'contractuel'
    );

    const rows = filteredClients.map(client => ({
        id: client._id,
        ...client,
    }));

    return (
        <Box m="1.5rem 2.5rem">
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
                Liste des clients
            </Typography>

            <Tabs value={tabValue} onChange={handleTabChange} aria-label="client-type-tabs">
                <Tab label="Personnel" />
                <Tab label="Contractuel" />
            </Tabs>

            <Box
                height="80vh"
                sx={{
                    "& .MuiDataGrid-root": {
                        border: "none",
                    },
                    "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderBottom: "none",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    "& .MuiDataGrid-footerContainer": {
                        backgroundColor: theme.palette.background.alt,
                        color: theme.palette.secondary[100],
                        borderTop: "none",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${theme.palette.secondary[200]} !important`,
                    },
                }}
            >
                <DataGrid
                    rows={rows}
                    columns={columns}
                    components={{ Toolbar: DataGridCustomToolbar }}
                    componentsProps={{
                        toolbar: { openPopup, setOpenPopup },
                    }}
                />
            </Box>
            <Popup
                title={editClient ? "Modifier le client" : "Formulaire du Client"}
                openPopup={openPopup}
                setOpenPopup={handleClosePopup}
            >
                <FormClient 
                    setOpenPopup={setOpenPopup} 
                    editClient={editClient}
                    onSuccess={fetchClients}
                />
            </Popup>
        </Box>
    );
};

export default ClientTab;
