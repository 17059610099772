import React, { useState } from "react";
import {
  Box, Button, IconButton, TextField, MenuItem, Typography, Tabs, Tab, Grid,
  Select
} from "@mui/material";
import { RemoveShoppingCart, ShoppingCartCheckout } from "@mui/icons-material";
import { motion } from "framer-motion";
import { useTheme } from "@mui/material/styles";


const CartItem = ({
  cart, onCheckout, onRemove, onQuantityChange, onPaymentMethodChange, paymentMethod, setClientId, clientName, setClientName, name, setName, clientList, clientType, setClientType, handleClientListFilter, selectedTab, handleTabChange
}) => {
  const theme = useTheme();
  const [errors, setErrors] = useState({});

  // Function to handle client name change

  const totalAmount = cart.reduce((total, item) => total + item.quantity * item.price_vente, 0);

  const renderClientTab = () => (
    <Box mt={2}>
      <TextField
        label="Nom du Client"
        variant="outlined"
        value={clientName}
        onChange={(e) => setClientName(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Select
        label="Payment Method"
        value={paymentMethod}
        onChange={(e) => onPaymentMethodChange(e.target.value)}
        variant="outlined"
        displayEmpty
        fullWidth
      >
        <MenuItem value="CASH">CASH</MenuItem>
        <MenuItem value="KCB">KCB</MenuItem>
        <MenuItem value="BANCOBU">BANCOBU</MenuItem>
        <MenuItem value="IBB">IBB</MenuItem>
        <MenuItem value="BCB">BCB</MenuItem>
      </Select>
    </Box>
  );

  const renderContractClientTab = () => (
    <Box mt={2}>
      <Typography variant="h6" mb={2}>
        Achat pour Client Personnel/Contrat
      </Typography>
      <Grid container spacing={2} mb={2}>
        {/* Client Type */}
        <Grid item xs={4}>
          <TextField
            fullWidth
            select
            label="Type de Client"
            value={clientType}
            onChange={(e) => setClientType(e.target.value)}
            error={!!errors.clientType}
            helperText={errors.clientType}
          >
            <MenuItem value="">--Sélectionner le type de client--</MenuItem>
            <MenuItem value="personnel">personnel</MenuItem>
            <MenuItem value="contractuel">contractuel</MenuItem>
          </TextField>
        </Grid>
        {/* Client Name */}
        <Grid item xs={8}>
          <TextField
            fullWidth
            select
            label="Nom du Client"
            value={name}
            onChange={(e) => {
              const selectedClient = clientList.find(client => client.name === e.target.value);
              if (selectedClient) {
                setName(selectedClient.name);
                setClientId(selectedClient._id); // Set the selected client's ID
              }
            }}
            error={!!errors.clientName}
            helperText={errors.clientName}
          >
            <MenuItem value="">--Sélectionner un client--</MenuItem>
            {handleClientListFilter(clientType).map((client) => (
              <MenuItem key={client.id} value={client.name}>
                {client.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Box>
  );

  const renderCartItems = () => (
    <Box mt="1rem">
      {cart.length === 0 ? (
        <Typography variant="body1">La carte est vide.</Typography>
      ) : (
        <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
          {cart.map((item) => (
            <motion.li
              key={item._id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0.5rem 0",
                borderBottom: "1px solid #ddd",
                gap: "1rem",
              }}
            >
              <Box display="flex" alignItems="center" flexGrow={1}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="1rem">
                  <Typography variant="body1" fontWeight="bold">{item.name}:</Typography>
                  <Typography variant="body2">{item.category}, {item.desc}</Typography>
                </Box>
                <Box display="flex" alignItems="center" gap="1rem" ml="auto">
                  <TextField
                    label="Quantity"
                    type="number"
                    variant="outlined"
                    value={item.quantity}
                    onChange={(e) => onQuantityChange(item._id, e.target.value)}
                    size="small"
                    sx={{ width: "5rem" }}
                  />
                  <Typography variant="body2">
                    {item.quantity} x {item.price_vente} Fbu
                  </Typography>
                </Box>
              </Box>
              <IconButton
                size="small"
                onClick={() => onRemove(item._id)}
                sx={{
                  color: theme.palette.error.main,
                  "&:hover": { color: theme.palette.error.dark },
                }}
              >
                <RemoveShoppingCart />
              </IconButton>
            </motion.li>
          ))}
        </ul>
      )}
    </Box>
  );

  return (
    <Box
      mt="2rem"
      p="1.5rem"
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="10px"
      boxShadow="0 4px 10px rgba(0, 0, 0, 0.1)"
      bgcolor={theme.palette.background.default}
    >
      {/* Header with Checkout */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb="1rem">
        <Typography variant="h5">Carte</Typography>
        <IconButton
          size="large"
          sx={{
            background: theme.palette.primary.main,
            color: "#fff",
            "&:hover": { background: theme.palette.primary.dark },
          }}
          onClick={onCheckout}
        >
          <ShoppingCartCheckout fontSize="large" />
        </IconButton>
      </Box>

      {/* Tabs */}
      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Vente" />
        <Tab label="Credite un client Personnel/Contractuel" />
      </Tabs>

      {/* Tab Content */}
      {selectedTab === 0 ? renderClientTab() : renderContractClientTab()}

      {/* Cart Items */}
      {renderCartItems()}

      {/* Total and Checkout */}
      <Box mt="1rem">
        <Typography variant="h6">Total: {totalAmount} Fbu</Typography>
        <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} style={{ display: "flex", justifyContent: "center", marginTop: "1rem" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={onCheckout}
            sx={{
              padding: "0.8rem 2rem",
              fontWeight: "bold",
              textTransform: "uppercase",
              letterSpacing: "1px",
              backgroundColor: theme.palette.success.main,
              "&:hover": { backgroundColor: theme.palette.success.dark },
            }}
          >
            VENDRE
          </Button>
        </motion.div>
      </Box>
    </Box>
  );
};

export default CartItem;
