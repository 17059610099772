import React, { useEffect, useState } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  TextField,
  Grid,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Typography,
  Autocomplete,
} from "@mui/material";
import { Add, Delete } from '@mui/icons-material';

const FormRetrait = ({ setOpenPopup }) => {
  const [desc, setDesc] = useState("");
  const [products, setProducts] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().slice(0, 10));
  const [tableData, setTableData] = useState([{ id: 1, productId: "", nom: "", categorie: "", description: "", prix: 0, quantite: 0 }]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        setProducts(response.data.allproducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleAddRow = () => {
    setTableData([...tableData, { id: tableData.length + 1, productId: "", nom: "", categorie: "", description: "", prix: 0, quantite: 0 }]);
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, index) => index !== rowIndex));
    }
  };

  const handleRowChange = (rowIndex, key) => (event) => {
    const value = key === "quantite" ? parseFloat(event.target.value) || 0 : event.target.value;
    const updatedData = [...tableData];
    updatedData[rowIndex][key] = value;
    setTableData(updatedData);
  };

  const handleProductSelection = (rowIndex) => async (event, newValue) => {
    const selectedProductId = newValue ? newValue._id : "";
    const updatedData = [...tableData];
    updatedData[rowIndex].productId = selectedProductId;

    if (selectedProductId) {
      try {
        const response = await axios.get(`${server}/product/a-product/${selectedProductId}`);
        const product = response.data.products;
        updatedData[rowIndex] = {
          ...updatedData[rowIndex],
          prix: product.price_vente,
          categorie: product.category,
          description: product.desc,
          nom: product.name,
        };
        setTableData(updatedData);
      } catch (error) {
        console.error("Error fetching product details:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors = {};
    tableData.forEach((row, index) => {
      if (!row.productId) newErrors[`nom${index}`] = "Le produit est requis";
      if (!row.quantite || row.quantite <= 0) newErrors[`quantite${index}`] = "La quantité doit être supérieure à 0";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRetrait = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const formData = {
      products: tableData.map((item) => ({
        productId: item.productId,
        price_vente: item.prix,
        quantity: item.quantite,
      })),
      desc,
    };

    try {
      const response = await axios.post(`${server}/retrait/api/retrait`, formData, { withCredentials: true });
      if (response.data.success) {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.replace("/stocks");
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const calculateTotal = () => {
    return tableData.reduce((total, row) => total + (row.prix * row.quantite || 0), 0);
  };

  return (
    <Box m="20px">
      <Card elevation={3} style={{ borderRadius: '10px' }}>
        <CardHeader title="Retrait des produits" style={{ textAlign: 'center' }} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                label="Description"
                variant="outlined"
                fullWidth
                value={desc}
                onChange={(e) => setDesc(e.target.value)}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Inv. Date"
                type="date"
                variant="outlined"
                fullWidth
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
          <br />
          {tableData.map((row, index) => (
            <Grid container spacing={2} key={row.id} mb={2}>
              <Grid item xs={3}>
                <Autocomplete
                  value={products.find(p => p._id === row.productId) || null}
                  onChange={(e, newValue) => handleProductSelection(index)(e, newValue)}
                  options={products}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Nom du produit"
                      required
                      variant="outlined"
                      error={!!errors[`nom${index}`]}
                      helperText={errors[`nom${index}`]}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField fullWidth label="Catégorie" value={row.categorie} disabled />
              </Grid>
              <Grid item xs={3}>
                <TextField fullWidth label="Description" value={row.description} disabled />
              </Grid>
              <Grid item xs={1}>
                <TextField fullWidth label="Prix" value={row.prix} disabled />
              </Grid>
              <Grid item xs={1}>
                <TextField
                  fullWidth
                  label="Quantité"
                  type="number"
                  value={row.quantite}
                  onChange={handleRowChange(index, "quantite")}
                  error={!!errors[`quantite${index}`]}
                  helperText={errors[`quantite${index}`]}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton color="primary" onClick={handleAddRow}>
                  <Add />
                </IconButton>
                <IconButton color="secondary" onClick={() => handleDeleteRow(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Typography variant="h6" textAlign="right" mb={2}>
            Total: {calculateTotal()} Fbu
          </Typography>
          <Grid container justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleRetrait}>
              Retire
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FormRetrait;
