import React, { useEffect, useState } from "react";
import { Box, Button, InputAdornment, TextField, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import Popup from "components/Popup";
import {
  CancelPresentationSharp,
  CheckBox,
  DeleteOutline,
  ListAltOutlined,
} from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "server";
import { Link } from "react-router-dom";
import "./product.css";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";
import FormProduct from "scenes/form";
import DataGridProductToolBar from "components/DataGridProductToolBar";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";
import SearchIcon from "@mui/icons-material/Search";

const Products = () => {
  const theme = useTheme();
  const formatNumber = (number) => {
    return number.toLocaleString();
  };
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);
  // values to be sent to the backend
  const [openPopup, setOpenPopup] = useState(false);
  const [product, setProduct] = useState([]);
  const [userId1, setUserId1] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    axios.get(`${server}/product/get-all-products`).then((res) => {
      setProduct(res.data.allproducts);
    });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${server}/product/delete-product/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };


  const columns = [
    {
      field: "createdAt",
      headerName: "Creé le",
      flex: 2,
    },
    {
      field: "name",
      headerName: "Article",
      flex: 1,
    },
    {
      field: "categorie",
      headerName: "Categorie",
      flex: 1,
    },
    {
      field: "desc",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "price_achat",
      headerName: "Prix d'achat",
      flex: 1,
    },
    {
      field: "price_vente",
      headerName: "Prix de vente",
      flex: 2,
    },
    {
      field: "stock",
      headerName: "Quantité en Stock",
      flex: 1,
    },
    {
      field: "total",
      headerName: "Prix Total",
      flex: 2,
    },
    {
      field: "userName",
      headerName: "Fait par",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {data?.role === "superadmin" ? (
              <Link to={"/productEdit/" + params.row.id}>
                <button className="userListEdit">Edit</button>
              </Link>
            ) : (
              ""
            )}
            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() =>
                  setUserId1(params.row.id) || setOpenDelete(!openDelete)
                }
              />
            )}
          </>
        );
      },
    },
    {
      field: "Ajouterk",
      headerName: "Ajouter le stock",
      renderCell: (params) => {
        return (
          <Link to={"/ProductPriceEdit/" + params.row.id}>
            <button className="userListEdit">Ajouter</button>
          </Link>
        );
      },
    },
  ];


  const filteredProduct = product.filter((item) =>
    item.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const row = [];

  filteredProduct &&
   filteredProduct.forEach((item) => {
      row.push({
        id: item._id,
        name: item.name,
        categorie: item.category,
        desc: item.desc,
        price_achat: formatNumber(item.price_achat),
        price_vente: formatNumber(item.price_vente),
        stock: item.stock,
        total: formatNumber(item.total),
        userName: item.userName,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Produits" subtitle="Liste des Produits" />
      <FlexBetween>
      <Link to="/pendingproduct">
        <Button
          color="secondary"
          variant="contained"
          style={{ marginBottom: "6px" }}
          onClick={() => setOpenPopup(!openPopup)}
        >
          <ListAltOutlined />
          Les Produits en attente
        </Button>
      </Link>
      </FlexBetween>
      <TextField
        variant="standard"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher un produit..."
        sx={{
          width: "300px",
          mb: 2,
          "& .MuiInputBase-root": {
            color: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:before": {
            borderBottomColor: theme.palette.secondary[100],
          },
          "& .MuiInput-underline:after": {
            borderBottomColor: theme.palette.primary[500],
          },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon sx={{ color: theme.palette.secondary[100] }} />
            </InputAdornment>
          ),
        }}
      />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridProductToolBar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <Popup
        title="Product from"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <FormProduct setOpenPopup={setOpenPopup} />
      </Popup>
      <PopupConfirm
        title="Voulez-vous supprimer le produit "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(userId)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <CancelPresentationSharp />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

export default Products;
