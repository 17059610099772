import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Grid, Card, CardContent } from '@mui/material';

const ProductDetailsDialog = ({ open, onClose, products }) => {
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Détails des Produits</DialogTitle>
            <DialogContent>
                {products && products.length > 0 ? (
                    <Grid container spacing={2}>
                        {products.map((product) => (
                            <Grid item xs={12} sm={6} md={4} key={product.productId}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h6">{product.productName}</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Quantité: {product.quantity}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            Prix: {product.price} Fbu
                                        </Typography>
                                        {/* Uncomment below if you have product images */}
                                        {/* <img src={product.imageUrl} alt={product.productName} style={{ width: '100%', height: 'auto' }} /> */}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography>Aucun produit à afficher.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ProductDetailsDialog; 