import React, { useState } from 'react';
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button, 
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem 
} from '@mui/material';
import { toast } from 'react-toastify';

const PAYMENT_METHODS = ['CASH', 'KCB', 'BANCOBU', 'IBB', 'BCB'];

const PaymentDialog = ({ open, onClose, onPayment, credit }) => {
    const [amount, setAmount] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('CASH');
    const userId = localStorage.getItem("secretKeyesforzado7"); // Get userId from localStorage

    const handleSubmit = async () => {
        if (!amount || amount <= 0) {
            toast.warn("Veuillez entrer un montant valide supérieur à 0.");
            return;
        }

        if (!credit) {
            toast.error("Aucun crédit sélectionné.");
            return;
        }

        const parsedAmount = parseFloat(amount);
        try {
            await onPayment(credit._id, parsedAmount, paymentMethod, userId);
            setAmount('');
            setPaymentMethod('CASH');
            onClose();
        } catch (error) {
            toast.error("Erreur lors du traitement du paiement: " + 
                (error.response?.data?.message || "Une erreur inattendue s'est produite"));
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Effectuer un Paiement</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Montant du paiement"
                    type="number"
                    fullWidth
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel>Méthode de paiement</InputLabel>
                    <Select
                        value={paymentMethod}
                        label="Méthode de paiement"
                        onChange={(e) => setPaymentMethod(e.target.value)}
                    >
                        {PAYMENT_METHODS.map((method) => (
                            <MenuItem key={method} value={method}>
                                {method}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Annuler</Button>
                <Button onClick={handleSubmit}>Payer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentDialog; 