import React, { useState, useEffect } from 'react';
import {
    Box,
    Typography,
    Tabs,
    Tab,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    TextField,
    Card,
    CardContent,
    Grid,
    Chip,
} from '@mui/material';
import { VisibilityOutlined, MonetizationOn, Delete as DeleteIcon, CheckCircleOutline, CancelOutlined, CheckCircle, Error } from '@mui/icons-material';
import axios from 'axios';
import { server } from 'server';
import PaymentDialog from '../../components/PaymentDialog'; // Import the PaymentDialog
import ProductDetailsDialog from '../../components/ProductDetailsDialog'; // Import the ProductDetailsDialog
import { toast } from 'react-toastify';
import PaymentHistoryDialog from 'components/PaymentHistoryDialog';
import { useSelector } from 'react-redux';
import { useGetUserQuery } from 'state/api';
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CreditManager = () => {
    const [credits, setCredits] = useState([]);
    const [value, setValue] = useState(0); // For tab management
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);
    const [selectedCredit, setSelectedCredit] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [openProductDialog, setOpenProductDialog] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [openPaymentHistoryDialog, setOpenPaymentHistoryDialog] = useState(false);
    const [selectedPaymentHistory, setSelectedPaymentHistory] = useState([]);

    const userId = localStorage.getItem("secretKeyesforzado7");
    const { data } = useGetUserQuery(userId)

    // Fetch credit data from the backend
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${server}/credit/api/credits`);
                setCredits(response.data.credit);
            } catch (error) {
                console.error('Error fetching credits:', error);
                toast.error("Erreur lors de la récupération des crédits: " + 
                    (error.response?.data?.message || "Une erreur inattendue s'est produite"));
            }
        };
        fetchData();
    }, []);

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filterCredits = (credits) => {
        return credits.filter((credit) =>
            credit.clientName.toLowerCase().includes(searchQuery.toLowerCase())
        );
    };

    const handleOpenPaymentDialog = (credit) => {
        setSelectedCredit(credit);
        setOpenPaymentDialog(true);
    };

    const handleOpenProductDialog = (products) => {
        setSelectedProducts(products);
        setOpenProductDialog(true);
    };

    const handleDelete = async (creditId) => {
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce crédit ?")) {
            try {
                const response = await axios.delete(`${server}/credit/api/credits/${creditId}`);
                if (response.status === 200) {
                    setCredits(credits.filter(credit => credit._id !== creditId));
                    toast.success("Crédit supprimé avec succès.");
                }
            } catch (error) {
                console.error('Error deleting credit:', error);
                toast.error("Erreur lors de la suppression: " + 
                    (error.response?.data?.message || "Une erreur inattendue s'est produite"));
            }
        }
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const calculateTotalCredits = (clientType) => {
        return credits
            .filter(credit => credit.clientType === clientType)
            .reduce((total, credit) => total + credit.Totalcredit, 0);
    };

    const calculatePaidCredits = (clientType) => {
        return credits
            .filter(credit => credit.clientType === clientType)
            .reduce((total, credit) => total + credit.amountPaid, 0);
    };

    const calculateRemainingCredits = (clientType) => {
        return credits
            .filter(credit => credit.clientType === clientType)
            .reduce((total, credit) => total + credit.remainamount, 0);
    };

    const handlePayment = async (creditId, amount, paymentMethod) => {
        try {
            await axios.post(`${server}/credit/api/credit-payment`, { 
                creditId, 
                amount, 
                paymentMethod,
                userId 
            });
            const response = await axios.get(`${server}/credit/api/credits`);
            setCredits(response.data.credit);
            toast.success("Paiement effectué avec succès");
        } catch (error) {
            console.error('Error processing payment:', error);
            toast.error("Erreur de paiement: " + 
                (error.response?.data?.message || "Une erreur inattendue s'est produite"));
        }
    };

    const personnelCredits = filterCredits(credits).filter(credit => credit.clientType === 'personnel');
    const contractuelCredits = filterCredits(credits).filter(credit => credit.clientType === 'contractuel');

    // const renderPaymentHistory = (paymentHistory) => (
    //     <TableContainer component={Paper}>
    //         <Table>
    //             <TableHead>
    //                 <TableRow>
    //                     <TableCell>Date de paiement</TableCell>
    //                     <TableCell>Montant</TableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {paymentHistory.map((payment, index) => (
    //                     <TableRow key={index}>
    //                         <TableCell>{new Date(payment.date).toLocaleDateString('fr-FR')}</TableCell>
    //                         <TableCell>{payment.amount} Fbu</TableCell>
    //                     </TableRow>
    //                 ))}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // );

    const generateCreditBill = async (credit) => {
        try {
            // Initialize jsPDF document with 80mm width
            const productCount = credit.products.length;
            const baseHeight = 200;
            const additionalHeight = productCount * 15;
            const docHeight = baseHeight + additionalHeight;

            const doc = new jsPDF({
                orientation: "portrait",
                unit: "mm",
                format: [80, docHeight],
            });

            // Add logo
            const imgData = Logo;
            if (imgData) {
                doc.addImage(imgData, "PNG", 25, 5, 30, 30);
            }

            // Helper function for drawing lines
            const drawLine = (doc, yPosition) => {
                doc.line(5, yPosition, 75, yPosition);
            };

            // Title and Company Info
            let yPosition = 40;
            doc.setFontSize(16);
            doc.text("Facture Crédit", 40, yPosition, null, null, "center");

            // Company information
            doc.setFontSize(10);
            yPosition += 10;
            doc.text(`Nom de l'entreprise: Bismos High Tech`, 5, yPosition);
            doc.text("NIF: 400045190 / RC: 03436", 5, (yPosition += 5));
            doc.text("Avenue: KIGARAMA, Ngozi N0: 21", 5, (yPosition += 5));
            doc.text("Centre Fiscal: Ressort Nord", 5, (yPosition += 5));
            doc.text("Secteur d'activité: COM. General", 5, (yPosition += 5));
            doc.text("Forme Juridique: Personne Morale", 5, (yPosition += 5));

            // Credit details
            yPosition += 10;
            doc.text(`Date: ${new Date(credit.createdAt).toLocaleDateString('fr-FR')}`, 5, yPosition);
            doc.text(`Client: ${credit.clientName}`, 5, (yPosition += 5));
            doc.text(`Type de client: ${credit.clientType}`, 5, (yPosition += 5));

            // Products section
            yPosition += 10;
            doc.setFontSize(11);
            doc.text("Détails des produits:", 5, yPosition);

            credit.products.forEach((product, index) => {
                yPosition += 8;
                const productText = `${index + 1}. ${product.productName}`;
                const quantityText = `Qte: ${product.quantity}`;
                const priceText = `Prix: ${product.price} Fbu`;

                doc.text(productText, 5, yPosition);
                doc.text(`${quantityText} | ${priceText}`, 5, (yPosition += 5));
                drawLine(doc, yPosition += 5);
            });

            // Credit Summary
            yPosition += 10;
            doc.text(`Crédit Total: ${credit.Totalcredit} Fbu`, 5, yPosition);
            doc.text(`Montant Payé: ${credit.amountPaid} Fbu`, 5, (yPosition += 5));
            doc.text(`Montant Restant: ${credit.remainamount} Fbu`, 5, (yPosition += 5));

            // Footer
            yPosition += 15;
            doc.setFontSize(8);
            doc.text("Tel: 79 967 624 / 22303165", 40, yPosition, null, null, "center");
            doc.text("KCB:06691079043 | Bancobu:20002-11109-06571220101-09", 40, (yPosition += 5), null, null, "center");
            doc.text("www.bismos.com | E-mail: info@bismos.com", 40, (yPosition += 5), null, null, "center");

            // Save PDF
            doc.save(`credit_facture_${credit.clientName}.pdf`);
            toast.success("La facture a été générée avec succès");

        } catch (error) {
            console.error("Error generating credit bill:", error);
            toast.error("Erreur lors de la génération de la facture");
        }
    };

    const renderCreditTable = (credits) => (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date de création</TableCell>
                        <TableCell>Nom du client</TableCell>
                        <TableCell>Type de client</TableCell>
                        <TableCell>Produits</TableCell>
                        <TableCell>Crédit total</TableCell>
                        <TableCell>Montant payé</TableCell>
                        <TableCell>Montant restant</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Détails de paiement</TableCell>
                        <TableCell>Facture</TableCell>
                        {data?.role === "superadmin" && (
                            <TableCell>Actions</TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {credits.map((credit) => (
                        <TableRow key={credit._id}>
                            <TableCell>{new Date(credit.createdAt).toLocaleDateString('fr-FR')}</TableCell>
                            <TableCell>{credit.clientName}</TableCell>
                            <TableCell>{credit.clientType}</TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleOpenProductDialog(credit.products)}>
                                    <VisibilityOutlined />
                                </IconButton>
                            </TableCell>
                            <TableCell>{credit.Totalcredit} Fbu</TableCell>
                            <TableCell>{credit.amountPaid} Fbu</TableCell>
                            <TableCell>{credit.remainamount} Fbu</TableCell>
                            <TableCell>
                                {credit.isPaid ? (
                                    <Chip icon={<CheckCircle />} label="Paid" color="success" size="small" />
                                ) : (
                                    <Chip icon={<Error />} label="Unpaid" color="error" size="small" />
                                )}
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => handleOpenPaymentHistory(credit.paymentHistory)}>
                                    <VisibilityOutlined />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={() => generateCreditBill(credit)}>
                                    <img
                                        src={pdfPicture}
                                        alt="Generate Bill"
                                        style={{
                                            width: "28px",
                                            height: "28px",
                                            cursor: "pointer",
                                            transition: "transform 0.3s",
                                        }}
                                    />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <Box display="flex" gap={1}>
                                    <IconButton onClick={() => handleOpenPaymentDialog(credit)}>
                                        <MonetizationOn />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(credit._id)} color="error">
                                        <DeleteIcon />
                                    </IconButton>
                                </Box>
                            </TableCell>
                            
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const handleOpenPaymentHistory = (paymentHistory) => {
        setSelectedPaymentHistory(paymentHistory);
        setOpenPaymentHistoryDialog(true);
    };

    return (
        <Box m="20px">
            <Typography variant="h4">Gestion des Crédits</Typography>
            <br />
            {/* Summary Card */}
            <Box mb={2}>
                <Card>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Résumé des Crédits - {value === 0 ? 'Clientèle Personnelle' : 'Clients Contractuels'}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                                <Paper elevation={3} sx={{ padding: 2 }}>
                                    <Typography variant="body1">Total des Crédits</Typography>
                                    <Typography variant="h5">
                                        {calculateTotalCredits(value === 0 ? 'personnel' : 'contractuel')} Fbu
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper elevation={3} sx={{ padding: 2 }}>
                                    <Typography variant="body1" color="success.main">Montant Total Payé</Typography>
                                    <Typography variant="h5" color="success.main">
                                        {calculatePaidCredits(value === 0 ? 'personnel' : 'contractuel')} Fbu
                                    </Typography>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Paper elevation={3} sx={{ padding: 2 }}>
                                    <Typography variant="body1" color="error.main">Montant Restant</Typography>
                                    <Typography variant="h5" color="error.main">
                                        {calculateRemainingCredits(value === 0 ? 'personnel' : 'contractuel')} Fbu
                                    </Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>

            <Box display="flex" gap={2} mb={2}>
                <TextField
                    placeholder="Rechercher par nom de client"
                    variant="outlined"
                    size="small"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    sx={{ flexGrow: 1 }}
                />
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
                <Tabs value={value} onChange={handleTabChange}>
                    <Tab label="Clientèle Personnelle" />
                    <Tab label="Clients Contractuels" />
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                {renderCreditTable(personnelCredits)}
            </TabPanel>

            <TabPanel value={value} index={1}>
                {renderCreditTable(contractuelCredits)}
            </TabPanel>

            <PaymentDialog
                open={openPaymentDialog}
                onClose={() => setOpenPaymentDialog(false)}
                onPayment={handlePayment}
                credit={selectedCredit}
            />

            <ProductDetailsDialog
                open={openProductDialog}
                onClose={() => setOpenProductDialog(false)}
                products={selectedProducts}
            />

            <PaymentHistoryDialog
                open={openPaymentHistoryDialog}
                onClose={() => setOpenPaymentHistoryDialog(false)}
                paymentHistory={selectedPaymentHistory}
            />
        </Box>
    );
};

// TabPanel component for content display in tabs
const TabPanel = ({ children, value, index }) => {
    return (
        <div role="tabpanel" hidden={value !== index}>
            {value === index && <Box p={3}>{children}</Box>}
        </div>
    );
};

export default CreditManager;
