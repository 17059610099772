import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const PaymentHistoryDialog = ({ open, onClose, paymentHistory }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Historique des paiements</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date de paiement</TableCell>
                            <TableCell>Montant</TableCell>
                            <TableCell>Méthode</TableCell>
                            <TableCell>Traité par</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paymentHistory.map((payment, index) => (
                            <TableRow key={index}>
                                <TableCell>{new Date(payment.date).toLocaleDateString('fr-FR')}</TableCell>
                                <TableCell>{payment.amount} Fbu</TableCell>
                                <TableCell>{payment.paymentMethod}</TableCell>
                                <TableCell>{payment.processedBy}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Fermer</Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentHistoryDialog; 