import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Grid,
  IconButton,
  Typography,
  Tabs,
  Tab,
  MenuItem,
  Select,
  Autocomplete,
} from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../../src/server"; // Ensure this is correct

const Transactions = () => {
  const [customerName, setCustomerName] = useState("");
  const [name, setName] = useState("");
  const [clientType, setClientType] = useState(""); // New state for client type
  const [products, setProducts] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString().slice(0, 10));
  const [paymentMethod, setPaymentMethod] = useState("CASH");
  const [id, setId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [tableData, setTableData] = useState([
    { id: 1, productId: "", nom: "", categorie: "", description: "", prix: 0, quantite: 0 },
  ]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [errors, setErrors] = useState({});
  const [clientList, setClientList] = useState([]); // List for clients

  useEffect(() => {
    const getUserIdFromStorage = async () => {
      const storedUserId = localStorage.getItem("secretKeyesforzado7");
      if (storedUserId) {
        setUserId(storedUserId);
      }
    };

    getUserIdFromStorage();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        setProducts(response.data.allproducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    const fetchClients = async () => {
      // Fetch clients based on type
      try {
        const response = await axios.get(`${server}/client/get-all-clients`);
        setClientList(response.data.client);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchProducts();
    fetchClients();
  }, []);

  const handleClientListFilter = (clientType) => {

    // Safely filter clients based on client type
    if (!clientList || clientList.length === 0) {
      return [];
    }
    const filteredClients = clientList.filter((client) => client.clientType === clientType);
    return filteredClients;
  };


  const handleAddRow = () => {
    setTableData([...tableData, { id: tableData.length + 1, productId: "", nom: "", categorie: "", description: "", prix: 0, quantite: 0 }]);
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, index) => index !== rowIndex));
    }
  };

  const handleChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleRowChange = (rowIndex, key) => (event) => {
    const value = key === "quantite" ? parseFloat(event.target.value) || 0 : event.target.value;
    const updatedData = [...tableData];
    updatedData[rowIndex][key] = value;
    setTableData(updatedData);
  };

  const handleProductSelection = (rowIndex) => async (event) => {
    const selectedProductId = event.target.value;
    const updatedData = [...tableData];
    updatedData[rowIndex].productId = selectedProductId;

    try {
      const response = await axios.get(`${server}/product/a-product/${selectedProductId}`);
      const product = response.data.products;
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        prix: product.price_vente,
        categorie: product.category,
        description: product.desc,
        nom: product.name,
      };
      setTableData(updatedData);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const validateForm = () => {
    const newErrors = {};
    // if (!customerName) newErrors.customerName = "Le nom du client est requis";
    // if (!paymentMethod) newErrors.paymentMethod = "Le mode de paiement est requis";
    if (selectedTab === 1 && !clientType) newErrors.clientType = "Le type de client est requis"; // Validate client type in Tab 1
    tableData.forEach((row, index) => {
      if (!row.productId) newErrors[`nom${index}`] = "Le produit est requis";
      if (!row.quantite || row.quantite <= 0) newErrors[`quantite${index}`] = "La quantité doit être supérieure à 0";
    });
    toast.error(setErrors(newErrors));
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveSale = async () => {
    if (!validateForm()) return;

    const formData = {
      userId,
      customerName,
      paymentMethod: paymentMethod,
      products: tableData.map((item) => ({
        productId: item.productId,
        price_vente: item.prix,
        quantity: item.quantite,
      })),
    };

    const CreditData = {
      userId,
      clientType,
      clientId: id,
      products: tableData.map((item) => ({
        productId: item.productId,
        price_vente: parseInt(item.prix),  // Ensure it's a valid number
        quantity: parseInt(item.quantite),
      })),
    };

    try {
      const endpoint = selectedTab === 0 ? `${server}/sells/api/ventes` : `${server}/credit/api/purchase-credit`;
      const data = selectedTab === 0 ? formData : CreditData;
      const response = await axios.post(endpoint, data);
      if (response.data.success) {
        toast.success("Vente créée avec succès !");

        if (selectedTab === 1) {
          setTimeout(() => {
            window.location.replace("/credit");
          }, 1000);
        }
        setTimeout(() => {
          window.location.replace("/ventes");
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Une erreur est survenue");
    }
  };


  const calculateTotal = () => {
    return tableData.reduce((total, row) => total + (row.prix * row.quantite || 0), 0);
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);

    // Only reset customerName if switching from Tab 0 to Tab 1
    setCustomerName("");  // Reset if switching to the second tab without a clientType selected
    setClientType("");  // Reset client type when switching tabs
    setErrors({});  // Clear errors when switching tabs
  };


  return (
    <Box m="20px">
      <Typography variant="h4" textAlign="center" mb={2}>
        Vente
      </Typography>

      <Tabs value={selectedTab} onChange={handleTabChange} centered>
        <Tab label="Vente" />
        <Tab label="Credite un client Personnel/Contractuel" />
      </Tabs>

      {selectedTab === 0 && (
        <Box mt={2}>
          <Typography variant="h6" mb={2}>
            Achat pour Client Régulier
          </Typography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={8}>
              <TextField
                fullWidth
                label="Nom du Client"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
                error={!!errors.customerName}
                helperText={errors.customerName}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Date de la facture"
                type="date"
                value={invoiceDate}
                onChange={handleChange(setInvoiceDate)}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label="Payment Method"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
                variant="outlined"
                displayEmpty
                fullWidth
              >
                <MenuItem value="CASH">CASH</MenuItem>
                <MenuItem value="KCB">KCB</MenuItem>
                <MenuItem value="BANCOBU">BANCOBU</MenuItem>
                <MenuItem value="IBB">IBB</MenuItem>
                <MenuItem value="BCB">BCB</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Box>
      )}

      {selectedTab === 1 && (
        <Box mt={2}>
          <Typography variant="h6" mb={2}>
            Achat pour Client Personnel/Contrat
          </Typography>
          <Grid container spacing={2} mb={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                select
                label="Type de Client"
                value={clientType}
                onChange={(e) => setClientType(e.target.value)}
                error={!!errors.clientType}
                helperText={errors.clientType}
              >
                <MenuItem value="">--Sélectionner le type de client--</MenuItem>
                <MenuItem value="personnel">personnel</MenuItem>
                <MenuItem value="contractuel">contractuel</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={8}>
              <TextField
                fullWidth
                select
                label="Nom du Client"
                value={name}
                onChange={(e) => {
                  const selectedClient = clientList.find(client => client.name === e.target.value);
                  if (selectedClient) {
                    setName(selectedClient.name);
                    setId(selectedClient._id); // Set the selected client's ID
                    console.log("Selected Client ID:", selectedClient._id);
                  }
                }}
                error={!!errors.name}
                helperText={errors.name}
              >
                <MenuItem value="">--Sélectionner un client--</MenuItem>
                {handleClientListFilter(clientType).map((client) => (
                  <MenuItem key={client.id} value={client.name}>
                    {client.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Date de Contrat"
                type="date"
                value={invoiceDate}
                onChange={(e) => setInvoiceDate(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      {tableData.map((row, index) => (
        <Grid container spacing={2} key={row.id} mb={2}>
          <Grid item xs={3}>
            <Autocomplete
              value={products.find(p => p._id === row.productId) || null}
              onChange={(e, newValue) => {
                if (newValue) {
                  handleProductSelection(index)({ target: { value: newValue._id } }); // Update the product selection
                }
              }}
              options={products}
              getOptionLabel={(option) => option.name || ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nom du produit"
                  required
                  variant="outlined"
                  fullWidth
                  error={!!errors[`nom${index}`]}
                  helperText={errors[`nom${index}`]}
                />
              )}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField fullWidth label="Catégorie" value={row.categorie} disabled />
          </Grid>
          <Grid item xs={3}>
            <TextField fullWidth label="Description" value={row.description} disabled />
          </Grid>
          <Grid item xs={1}>
            <TextField fullWidth label="Prix" value={row.prix} disabled />
          </Grid>
          <Grid item xs={1}>
            <TextField
              fullWidth
              label="Quantité"
              type="number"
              value={row.quantite}
              onChange={handleRowChange(index, "quantite")}
              error={!!errors[`quantite${index}`]}
              helperText={errors[`quantite${index}`]}
            />
          </Grid>
          <Grid item xs={2}>
            <IconButton color="primary" onClick={handleAddRow}>
              <AddCircleOutline />
            </IconButton>
            <IconButton color="secondary" onClick={() => handleDeleteRow(index)}>
              <RemoveCircleOutline />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <Typography variant="h6" textAlign="right" mb={2}>
        Total: {calculateTotal()} Fbu
      </Typography>

      <Grid container justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleSaveSale}>
          Enregistrer la vente
        </Button>
      </Grid>
    </Box>
  );
};

export default Transactions;

